import * as React from 'react';
import {
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';

const docu = "https://google.com";

export default function Canuelas() {

  return (<React.Fragment>
    {<>
        <Button component={Link} to="/">
          Proyecto Canuelas
        </Button>
        <Button component={Link} to="/products">
          Productos
        </Button>
        <Button component={Link} to="/users">
          Users
        </Button>
        <Button component={Link} to="/dashboard">
          Dashboard
        </Button>
        <iframe
          src={docu}
          width="100%"
          height="600"
        >
        </iframe>
      </>}
    </React.Fragment>
  );
}
