// src/Login.js

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../auth/AuthContext';
import { axiosInstance as axios } from '../client/api-client';

const credentials = `secureid:ultrasecret`;
const encodedCredentials = btoa(credentials);

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {login} = useAuth();
  const navigate = useNavigate();
  
  const handleLogin = async (e: any) => {
      e.preventDefault();
      setLoading(true);
      setError('');

      try {
          const response = await axios.post(`/token`, {
              grant_type: 'password',
              username: username,
              password: password
          }, {
              headers: {
                  'Authorization': 'Basic ' + encodedCredentials,
                  'Content-Type': 'application/x-www-form-urlencoded'
              }
          });

          // Store the token (e.g., in localStorage)
          localStorage.setItem('greberp-access-token', response.data.greberp-access-token);
          login();
          // Redirect or update the UI based on successful login
          console.log('Login successful', response.data);
          navigate("/");
      } catch (err) {
          setError('Login failed. Please check your credentials and try again.');
          console.error(err);
      } finally {
          setLoading(false);
      }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
