// src/auth/AuthContext.js

import React, { createContext, useState, useContext, MouseEventHandler,
  useEffect,
} from 'react';
const { setupAxiosInterceptors } = require('../client/api-client');

class AuthContextClass {
  public isAuthenticated: boolean = false;
  public login: Function = () => {};
  public logout: MouseEventHandler<HTMLButtonElement> = () => {};
}

// Create a Context for authentication
const AuthContext  = createContext(new AuthContextClass());

// Create a provider component
export const AuthProvider: React.FC<any> = ({ children }) => {
  useEffect(() => {
    // Set up the Axios interceptors when the app loads
    setupAxiosInterceptors();
  }, []);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("greberp-access-token"));

  const login = () => setIsAuthenticated(true);
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('greberp-access-token');
    localStorage.removeItem('greberp-user');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
