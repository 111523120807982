import React, { useEffect, useCallback } from 'react';
import Sheet from '@mui/joy/Sheet';

import MessagesPane from './MessagesPane';
import ChatsPane from './ChatsPane';
import { ChatProps } from '../types';
import { axiosInstance as axios } from '../../../client/api-client';

// import { chats } from '../data';

export default function MyProfile() {
  const [selectedChat, setSelectedChat] = React.useState<ChatProps>();
  const [chats, setChats] = React.useState<ChatProps[]>();

  useEffect(() => {
    fetchChats();
    return () => {}
  }, []);

  const fetchChats = useCallback(async () => {
    try {
      const response = await axios.get(`/ml/messages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`
        }
      });
      setChats(response.data);
      setSelectedChat(response.data[0]);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  },[localStorage.getItem('greberp-access-token')]);

  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', md: 0 },
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'minmax(min-content, min(30%, 400px)) 1fr',
        },
      }}
    >
      {chats && selectedChat?.id && <Sheet
        sx={{
          position: { xs: 'fixed', sm: 'sticky' },
          transform: {
            xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
            sm: 'none',
          },
          transition: 'transform 0.4s, width 0.4s',
          zIndex: 100,
          width: '100%',
          top: 52,
        }}
      ><ChatsPane
          chats={chats}
          selectedChatId={selectedChat.id}
          setSelectedChat={setSelectedChat}
        />
      </Sheet>}
      {selectedChat?.id && <MessagesPane chat={selectedChat} />}
    </Sheet>
  );
}
