/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { axiosInstance as axios } from '../client/api-client';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

/*
{
    "query": "",
    "results": [
        {
            "payments": [
                {
                    "reason": "Item De Prueba - Por Favor, No Ofertar",
                    "status_code": null,
                    "total_paid_amount": 25000,
                    "operation_type": "regular_payment",
                    "transaction_amount": 25000,
                    "transaction_amount_refunded": 0,
                    "date_approved": "2024-09-30T09:00:41.000-04:00",
                    "collector": {
                        "id": 2008067771
                    },
                    "coupon_id": null,
                    "installments": 1,
                    "authorization_code": "301299",
                    "taxes_amount": 0,
                    "id": 89212730656,
                    "date_last_modified": "2024-09-30T09:00:41.000-04:00",
                    "coupon_amount": 0,
                    "available_actions": [
                        "refund"
                    ],
                    "shipping_cost": 0,
                    "installment_amount": 25000,
                    "date_created": "2024-09-30T09:00:40.000-04:00",
                    "activation_uri": null,
                    "overpaid_amount": 0,
                    "card_id": null,
                    "status_detail": "accredited",
                    "issuer_id": "3",
                    "payment_method_id": "debmaster",
                    "payment_type": "debit_card",
                    "deferred_period": null,
                    "atm_transfer_reference": {
                        "transaction_id": null,
                        "company_id": null
                    },
                    "site_id": "MLA",
                    "payer_id": 2008129535,
                    "order_id": 2000009425678442,
                    "currency_id": "ARS",
                    "status": "approved",
                    "transaction_order_id": null
                }
            ],
            "fulfilled": null,
            "taxes": {
                "amount": null,
                "currency_id": null,
                "id": null
            },
            "order_request": {
                "change": null,
                "return": null
            },
            "expiration_date": "2024-10-28T09:00:41.000-04:00",
            "feedback": {
                "buyer": null,
                "seller": null
            },
            "shipping": {
                "id": null
            },
            "date_closed": "2024-09-30T09:00:41.000-04:00",
            "id": 2000009425678442,
            "manufacturing_ending_date": null,
            "order_items": [
                {
                    "item": {
                        "id": "MLA1452810311",
                        "title": "Item De Prueba - Por Favor, No Ofertar",
                        "category_id": "MLA3530",
                        "variation_id": null,
                        "seller_custom_field": null,
                        "global_price": null,
                        "net_weight": null,
                        "variation_attributes": [],
                        "warranty": "Sin garantía",
                        "condition": "new",
                        "seller_sku": "111111111111"
                    },
                    "quantity": 1,
                    "unit_price": 25000,
                    "full_unit_price": 25000,
                    "currency_id": "ARS",
                    "manufacturing_days": null,
                    "picked_quantity": null,
                    "requested_quantity": {
                        "measure": "unit",
                        "value": 1
                    },
                    "sale_fee": 5800,
                    "listing_type_id": "gold_special",
                    "base_exchange_rate": null,
                    "base_currency_id": null,
                    "bundle": null,
                    "element_id": 1
                }
            ],
            "date_last_updated": "2024-09-30T13:02:14+00:00",
            "last_updated": "2024-09-30T09:02:13.000-04:00",
            "comment": null,
            "pack_id": null,
            "coupon": {
                "amount": 0,
                "id": null
            },
            "shipping_cost": null,
            "date_created": "2024-09-30T09:00:39.000-04:00",
            "pickup_id": null,
            "status_detail": null,
            "tags": [
                "paid",
                "not_delivered",
                "test_order"
            ],
            "buyer": {
                "id": 2008129535,
                "nickname": "TESTUSER1961744769"
            },
            "seller": {
                "id": 2008067771,
                "nickname": "TESTUSER1336345024"
            },
            "total_amount": 25000,
            "paid_amount": 25000,
            "currency_id": "ARS",
            "status": "paid",
            "context": {
                "application": null,
                "product_id": null,
                "channel": "marketplace",
                "site": "MLA",
                "flows": []
            }
        }
    ],
    "sort": {
        "id": "date_asc",
        "name": "Date ascending"
    },
    "available_sorts": [
        {
            "id": "date_desc",
            "name": "Date descending"
        }
    ],
    "filters": [],
    "paging": {
        "total": 1,
        "offset": 0,
        "limit": 51
    },
    "display": "complete"
}
*/
interface Customer {
  id: number,
  nickname: string,
}

interface Item {
  id: string,
  title: string,
}
interface OrderItem {
  item: Item,
  quantity: number,
  price: number,
}
interface Row {
  id: string
  status: string
  total_amount: number
  date_created: Date,
  date_closed: Date,
  buyer: Customer,
  seller: Customer,
  order_items: OrderItem[],
}

const defaultRows: Row[] = [
  {
    id: '102',
    date: 'Feb 3, 2023',
    date_closed: 'Feb 3, 2023',
    status: 'Refunded',
    total_amount: 100,
  },
  {
    id: '101',
    date_created: 'Feb 3, 2023',
    date_closed: 'Feb 3, 2023',
    status: 'Paid',
    total_amount: 50,
  }
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Rename</MenuItem>
        <MenuItem>Move</MenuItem>
        <Divider />
        <MenuItem color="danger">Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}
export default function OrderTable() {
  const [order, setOrder] = React.useState<Order>('desc');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState<Row[]>(defaultRows);  

  React.useEffect(() => {
    fetchOrders();
    return () => {}
  }, []);

  const fetchOrders = React.useCallback(async () => {
    try {
      const response = await axios.get(`/ml/orders`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`
        }
      });
      setRows(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  },[localStorage.getItem('greberp-access-token')]);

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        >
          <Option value="paid">Paid</Option>
          <Option value="pending">Pending</Option>
          <Option value="refunded">Refunded</Option>
          <Option value="cancelled">Cancelled</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Category</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="refund">Refund</Option>
          <Option value="purchase">Purchase</Option>
          <Option value="debit">Debit</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Customer</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="olivia">Olivia Rhye</Option>
          <Option value="steve">Steve Hampton</Option>
          <Option value="ciaran">Ciaran Murray</Option>
          <Option value="marina">Marina Macdonald</Option>
          <Option value="charles">Charles Fulton</Option>
          <Option value="jay">Jay Hoper</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for order</FormLabel>
          <Input size="sm" placeholder="Search" startDecorator={<SearchIcon />} />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 120, padding: '12px 6px' }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  endDecorator={<ArrowDropDownIcon />}
                  sx={[
                    {
                      fontWeight: 'lg',
                      '& svg': {
                        transition: '0.2s',
                        transform:
                          order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                      },
                    },
                    order === 'desc'
                      ? { '& svg': { transform: 'rotate(0deg)' } }
                      : { '& svg': { transform: 'rotate(180deg)' } },
                  ]}
                >
                  Invoice
                </Link>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>Items</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Date Closed</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
              <th style={{ width: 240, padding: '12px 6px' }}>Buyer</th>
              <th style={{ width: 140, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {[...rows].sort(getComparator(order, 'id')).map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size="sm"
                    checked={selected.includes(row.id)}
                    color={selected.includes(row.id) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Typography level="body-xs">{row.id}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.order_items?.map(i => i.item.title).join(', ')}</Typography>  
                </td>
                <td>
                  <Typography level="body-xs">{row.date_closed}</Typography>
                </td>
                <td>
                  <Chip
                    variant="soft"
                    size="sm"
                    startDecorator={
                      {
                        Paid: <CheckRoundedIcon />,
                        Refunded: <AutorenewRoundedIcon />,
                        Cancelled: <BlockIcon />,
                      }[row.status]
                    }
                    color={
                      {
                        Paid: 'success',
                        Refunded: 'neutral',
                        Cancelled: 'danger',
                      }[row.status] as ColorPaletteProp
                    }
                  >
                    {row.status}
                  </Chip>
                </td>
                {<td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Avatar size="sm">{row.buyer?.nickname.charAt(0)}</Avatar>
                    <div>
                      <Typography level="body-xs">{row.buyer?.nickname}</Typography>
                    </div>
                  </Box>
                </td>}
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Link level="body-xs" component="button">
                      Download
                    </Link>
                    <RowMenu />
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
