const API_URL = `${process.env.REACT_APP_API_URL}`;

export const createProduct = async (productData: any) => {
  const response = await fetch(`${API_URL}/products`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(productData),
  });
  return response.json();
};

export const getProducts = async () => {
  const response = await fetch(`${API_URL}/products`);
  return response.json();
};

export const addStock = async (id: string, stockData: any) => {
  await fetch(`${API_URL}/products/${id}/stock`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(stockData),
  });
};

export const consumeStock = async (id: string, quantityData: any) => {
  await fetch(`${API_URL}/products/${id}/consume`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(quantityData),
  });
};
