import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance as axios } from '../client/api-client';
import ProductForm from '../components/ProductForm';
import StockForm from '../components/StockForm';
import ConsumeForm from '../components/ConsumeForm';
import ProductList from '../components/ProductList';
import Login from '../components/Login';
import {
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { Box, Sheet } from '@mui/joy';
import { axiosInstance } from '../client/api-client';

const Dashboard:React.FC = () => {
  const [products, setProducts] = useState([]);  
  
  useEffect(() => {
    fetchProducts();
    return () => {}
  }, []);

  const { logout } = useAuth();

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`/products`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`
        }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  },[localStorage.getItem('greberp-access-token')]);
  const handleProductCreated = (newProduct: never) => {
    setProducts([...products, newProduct]);
  };

  const handleAddStock = useCallback(async (productId: string, stock: string) => {
    try {
      await axios.post(`/products/${productId}/stock`, { stock }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`
        }
      });
      fetchProducts();
    } catch (error) {
      console.error('Error adding stock:', error);
    }
  }, [localStorage.getItem('greberp-access-token')]);

  const handleConsumeStock = useCallback(async (productId: string, quantity: string) => {
    try {
      await axios.post(`/products/${productId}/consume`, { quantity }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('greberp-access-token')}`
        }
      });
      fetchProducts();
    } catch (error) {
      console.error('Error consuming stock:', error);
    }
  }, [localStorage.getItem('greberp-access-token')]);

  const handleStockChange = useCallback(async (productId: string, quantity: string) => {
    try {
      fetchProducts();
    } catch (error) {
      console.error('Error updating stock:', error);
    }
  }, [localStorage.getItem('greberp-access-token')]);

  return (<>
      <Button component={Link} to="/">
        Inicio
      </Button>
      <Button component={Link} to="/products">
        Productos
      </Button>
      <Button component={Link} to="/users">
        Users
      </Button>
      <Button component={Link} to="/dashboard">
        Dashboard
      </Button>
      <ProductForm onProductCreated={handleProductCreated} />
      <ProductList products={products} handleStockChange={handleStockChange} />
      <StockForm products={products} onAddStock={handleAddStock} />
      <ConsumeForm products={products} onConsumeStock={handleConsumeStock} />
      <button type="button" onClick={logout}>Logout</button>
    </>
  );
};

export default Dashboard;
