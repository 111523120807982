export default {
    "sidebar.home": "Home",
    "sidebar.dashboard": "Dashboard",
    "sidebar.products": "Products",
    "sidebar.products.list": "List",
    "sidebar.products.add": "New",
    "sidebar.products.stock": "Stock",
    "sidebar.products.ml.list": "Publis ML",
    "sidebar.messages": "Messages",
    "sidebar.users": "Users",
    "sidebar.support": "Support",
    "sidebar.settings": "Settings",
    "sidebar.orders": "Orders",
    "sidebar.orders.list": "List",
    "sidebar.orders.add": "New",
}