import { createBrowserRouter } from "react-router-dom";
import Canuelas from "./containers/Canuelas";
import Dashboard from "./containers/Dashboard";
import Login from "./components/Login";
import SecureRoute from "./auth/SecureRoute";
import JoyDashboard from "./containers/JoyDashboard";
import JoyUsers from "./containers/JoyUsers";
import JoySignInSide from "./containers/sign-in-side/App";
import JoyMessages from "./containers/messages/App";
import JoyProductForm from "./containers/JoyProductForm";
import JoyProducts from "./containers/JoyProducts";
import JoyOrders from "./containers/JoyOrders";
import JoyPublisMl from "./containers/JoyPublisMl";

const router = createBrowserRouter([
  { path: "/", element: <SecureRoute component={JoyDashboard} /> },
  { path: "/orders", element: <SecureRoute component={JoyOrders} /> },
  { path: "/products", element: <SecureRoute component={JoyProducts} /> },
  { path: "/products/ml", element: <SecureRoute component={JoyPublisMl} /> },
  { path: "/products/new", element: <SecureRoute component={JoyProductForm} />},
  { path: "/products/stock", element: <SecureRoute component={Dashboard} />},
  { path: "/dashboard", element: <SecureRoute component={JoyDashboard} child={Canuelas} />},
  { path: "/users", element: <SecureRoute component={JoyUsers} /> },
  { path: "/login", element: <Login /> },
  { path: "/signin", element: <JoySignInSide /> },
  { path: "/signup", element: <JoySignInSide /> },
  { path: "/oauth2-callback", element: <JoySignInSide /> },
  { path: "/messages", element: <SecureRoute component={JoyMessages} /> },
]);

export default router;