import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Function to handle 401 errors
const setupAxiosInterceptors = () => {
  axiosInstance.interceptors.response.use(
    (response) => {
      // Return the response normally if status is not 401
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle the 401 error globally here
        console.log('Unauthorized! Redirecting to login...');
        
        // Example: You could clear the auth token, and redirect to login page
        localStorage.removeItem('greberp-access-token'); // Assuming you're storing token in localStorage
        window.location.href = '/signin'; // Redirect to login
      }

      // Return any error that is not a 401 to be handled elsewhere
      return Promise.reject(error);
    }
  );
};

export { axiosInstance, setupAxiosInterceptors };
